import React from 'react';
import Main from '../containers/Layout';
import eBookPageContent from '../../content/pages/e-book.yml';
import EBooksContainer from '@/containers/Ebooks';

export interface EBookPageContent {
  pageTitle: {
    title1: string;
    description1: string;
    description2: string;
  };
  ebooks: {
    image: string;
    title: string;
    description: string;
    link: string;
  }[];
}
const Ebook: React.FunctionComponent = () => {
  const { pageTitle, ebooks } = eBookPageContent as unknown as EBookPageContent;
  return (
    <Main>
      <EBooksContainer content={{ pageTitle, ebooks }} />
    </Main>
  );
};

export default Ebook;
