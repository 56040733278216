import * as React from 'react';
import styled from 'styled-components';
import HubspotForm from 'react-hubspot-form';
import { Body1, Body2, H3 } from './Typography';

const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 8px 26px 0px #e7edf3;
  border-radius: 5px;
  background-color: #ffff;
  min-width: calc(300px - 1rem * 2);
  padding: 6rem 5rem 6rem 5rem;
  @media (max-width: 1200px) {
    flex-grow: 1;
  }
`;

const Image = styled.div`
  width: 420px;
  margin-bottom: 1rem;
  min-height: 27rem;
`;

const Description = styled(Body1)`
  margin-bottom: 4rem;
`;

const CoverImage = styled.img`
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 2rem;
`;

const RightSide = styled.div`
  padding-left: 7rem;
  flex: 1;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 73px;
  background: none;
  border: none;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 500;
  font-family: Montserrat;
  cursor: pointer;
`;

const StyledBody2 = styled(Body2)`
  font-weight: 700;
  margin-bottom: 1rem;
  color: #000000;
`;

interface EbookCardProps {
  image: string;
  title: string;
  link: string;
  description: string;
  handleBack: () => void;
}

const PreviewCard: React.FunctionComponent<EbookCardProps> = ({
  image,
  title,
  link,
  description,
  handleBack,
}) => {
  const downloadFile = () => {
    const url = link;

    const file = document.createElement(`a`);
    file.href = url;
    file.setAttribute(`download`, link.slice(7));

    document.body.appendChild(file);

    file.click();

    document.body.removeChild(file);
  };

  return (
    <div style={{ position: `relative` }}>
      <BackButton type="button" onClick={handleBack}>
        &larr; Back
      </BackButton>
      <Wrapper>
        <Image>{image && <CoverImage src={image} alt="cover" />}</Image>

        <RightSide>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <StyledBody2>Leave your details to download the ebook</StyledBody2>
          <HubspotForm
            portalId="20067109"
            formId="acba60f5-8030-46ce-83da-84e96802f3b9"
            onReady={() => {
              const script = document.createElement(`script`);
              script.src = `https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js`;
              const jqueryScript = document.getElementsByTagName(`script`);
              const src = Array.from(jqueryScript).filter(
                (item) => item.src === script.src,
              );
              if (src.length === 0) {
                document.body.appendChild(script);
              }
            }}
            onSubmit={downloadFile}
          />
        </RightSide>
      </Wrapper>
    </div>
  );
};

export default PreviewCard;
