import React, { useState } from 'react';
import styled from 'styled-components';
import { P, Subtitle1 } from '@/components/Typography';
import Section from '@/components/Section';
import { EBookPageContent } from '@/pages/e-book';
import EbookCard from '@/components/EbookCard';
import PreviewCard from '@/components/PreviewCard';

const Content = styled(Section)``;

const StyledSubtitle1 = styled(Subtitle1)`
  text-align: center;
  margin-bottom: 2rem;
`;

const Description1 = styled(P)`
  text-align: center;
`;

const Description2 = styled(Description1)`
  text-align: center;
  margin-bottom: 6rem;
  margin-top: 0.5rem;
`;

const Ebooks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  margin-top: 3rem;
  gap: 6rem;
`;

interface EbooksPageProps {
  content: EBookPageContent;
}

export interface Ebook {
  image: string;
  title: string;
  link: string;
  description: string;
}

const EBooksContainer: React.FunctionComponent<EbooksPageProps> = ({
  content: { pageTitle, ebooks },
}) => {
  const [previewMode, setPreviewMode] = useState(false);
  const [previewData, setPreviewData] = useState<Ebook>();

  const handleView = (el: Ebook) => {
    setPreviewData(el);
    setPreviewMode(true);
    window.scrollTo(0, 0);
  };
  return (
    <Content>
      <StyledSubtitle1>{pageTitle.title1}</StyledSubtitle1>
      <Description1>{pageTitle.description1}</Description1>
      <Description2>{pageTitle.description2}</Description2>

      <Ebooks>
        {!previewMode &&
          ebooks.map((el) => (
            <EbookCard
              key={el.title}
              image={el.image}
              title={el.title}
              description={el.description}
              handleView={() => {
                handleView(el);
              }}
            />
          ))}
      </Ebooks>
      {previewMode && (
        <PreviewCard
          {...previewData}
          handleBack={() => {
            setPreviewMode(false);
          }}
        />
      )}
    </Content>
  );
};

export default EBooksContainer;
