import * as React from 'react';
import styled from 'styled-components';
import ButtonDefault from './ButtonDefault';
import { Body1, H3 } from './Typography';

const Wrapper = styled.div`
  box-shadow: 0px 8px 26px 0px #e7edf3;
  border-radius: 5px;
  background-color: #ffff;
  min-width: calc(300px - 1rem * 2);
  padding: 2rem 3rem 2rem 3rem;
  @media (max-width: 1200px) {
    flex-grow: 1;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  min-height: 27rem;
`;

const CoverImage = styled.img`
  width: 65%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
`;

const TitleWrapper = styled.div`
  min-height: 13.5rem;
  margin-bottom: 2rem;
`;

const Title = styled(H3)`
  font-weight: bold;
  font-size: 22px;
`;

const Description = styled(Body1)`
  margin-top: 1rem;
`;

interface EbookCardProps {
  image: string;
  title: string;
  description: string;

  handleView: () => void;
}

const EbookCard: React.FunctionComponent<EbookCardProps> = ({
  image,
  title,
  description,
  handleView,
}) => (
  <Wrapper>
    <TitleWrapper>
      <Title>{title}</Title>

      <Description>{description.slice(0, 140)}......</Description>
    </TitleWrapper>
    <Image>{image && <CoverImage src={image} alt="cover" />}</Image>
    <Button>
      <ButtonDefault onClick={handleView}>View now</ButtonDefault>
    </Button>
  </Wrapper>
);

export default EbookCard;
